// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-enquiry-form-js": () => import("./../src/pages/enquiryForm.js" /* webpackChunkName: "component---src-pages-enquiry-form-js" */),
  "component---src-pages-explore-courses-js": () => import("./../src/pages/exploreCourses.js" /* webpackChunkName: "component---src-pages-explore-courses-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

